<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3" v-loading="loading">
            <div class="card-header">
                <h3 class="mb-0">Request best FX rate from bank</h3>
              </div>

            <div class="card-body min-vh">

              <div class="row justify-content-md-center">
                <div class="col-lg-8 col-md-12">

                  <el-form ref="formRequestFX" :model="form" :rules="formRule" :label-position="labelPosition" label-width="150px">
                    
                    <el-form-item label="From BankholderID" prop="from_bankholderid">
                      <el-select v-model="form.from_bankholderid" @change="getBalanceFrom" placeholder="From BankholderID" class="form-control-alternative">
                        <el-option v-for="item of bankAccount" :key="item.uid" :label="`${item.currency} (${item.cardholderid})`" :value="item.cardholderid"></el-option>
                      </el-select>
                      <div class="text-xs pl-3 mt-1" v-loading="loadingBalanceFrom">{{ balanceFrom }}</div>
                    </el-form-item>

                    <el-form-item label="To BankholderID" prop="to_bankholderid">
                      <el-select v-model="form.to_bankholderid" @change="getBalanceTo" placeholder="To BankholderID" class="form-control-alternative">
                        <el-option v-for="item of bankAccount" :key="item.uid" :label="`${item.currency} (${item.cardholderid})`" :value="item.cardholderid"></el-option>
                      </el-select>
                      <div class="text-xs pl-3 mt-1" v-loading="loadingBalanceTo">{{ balanceTo }}</div>
                    </el-form-item>

                    <el-form-item label="Amount" prop="amount">
                      <el-input type="number" v-model="form.amount" class="form-control-alternative"></el-input>
                    </el-form-item>
 
                  </el-form>
                </div>
              </div>
             
            </div>
            <div class="card-footer">
              <div class="row justify-content-xl-center">
                <div class="col-xl-12 text-center">
                  <el-button>Cancel</el-button>
                  <el-button type="primary" @click="onSubmit()" v-loading="submiting" :disabled="submiting">Confirm</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12">
          <div class="card mb-3">
              <div class="card-body">
                <Shortcut/>
              </div> 
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FxApi from '@/api/fx' 
import Utils from '@/utils'
import BankApi from '@/api/bankaccount'
import { isMobile } from "@/utils/helpers";
import Shortcut from '@/components/MenuShortcut.vue'
export default {
  components:{
    Shortcut
  },
  computed:{
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
  },
  data(){
    return {
      sameAccount: false,
      submiting: false,
      loading: false,
      bankAccount: [],
      balanceFrom: "",
      loadingBalanceFrom: false,
      balanceTo: "",
      loadingBalanceTo: false,
      form: {
        from_bankholderid: "",
        to_bankholderid: "",
        amount: "",
      },
      formRule:{
        from_bankholderid: [{required: true, message: "Please select from BankholderID", trigger: "blur"}],
        to_bankholderid: [{required: true, message: "Please select to BankholderID", trigger: "blur"}],
        amount: [{required: true, message: "Please enter amount", trigger: "blur"}]
      }
    }
  },
  methods:{
    async getBankAccount(){
      this.balanceFrom = ""
      this.balanceTo = ""
      this.loading = true;
      this.bankAccount = await Utils.getBankAccount()
      this.loading = false
    },
    checkSameAccount(){
      const fromCurrency = Utils.findObject(this.bankAccount, this.form.from_bankholderid, 'cardholderid')
      const toCurrency = Utils.findObject(this.bankAccount, this.form.to_bankholderid, 'cardholderid')

      if((fromCurrency && fromCurrency['currency']) === (toCurrency && toCurrency['currency'])){
        this.form.from_bankholderid = ""
        this.form.to_bankholderid = ""
        this.balanceFrom = ""
        this.balanceTo = ""
        this.$swal('Message', "FX not allow for same currency", 'error')
        this.sameAccount = true;
        return false
      }

      this.sameAccount = false;
      return true;
    },
    getBalanceFrom(bankholderid){
      this.balanceFrom = ""
      if(!this.checkSameAccount()) return false
      this.loadingBalanceFrom = true
      BankApi.getAccountBalance(bankholderid).then(({result, data})=>{
        this.loadingBalanceFrom = false
        if(result){
          this.balanceFrom = `Balance : ${data.balance}`
        }
      })
    },
    getBalanceTo(bankholderid){
      this.balanceTo = ""
      if(!this.checkSameAccount()) return false
      this.loadingBalanceTo = true
      BankApi.getAccountBalance(bankholderid).then(({result, data})=>{
        this.loadingBalanceTo = false
        if(result){
          this.balanceTo = `Balance : ${data.balance}`
        }
      })
    },
    onSubmit(){
      this.$refs.formRequestFX.validate(valid=>{
        if(valid){
          this.submiting = true
          this.form.amount = parseFloat(this.form.amount)
          FxApi.manualFX(this.form).then(({result, message})=>{
            this.submiting = false
            if(result){
              this.$swal(`Message`, `Request FX is success`, 'success').then(()=>{
                this.$refs.formRequestFX.resetFields()
                this.$emit("updateBalance");
              })
            }else{
              this.$swal('Message', message, 'error')
            }
          })
        }
      })
    }
  },
  async created(){
    await this.getBankAccount()
  }
};
</script>
<style></style>
